import React, {useState} from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import SubsciptionCounter from "./SubsciptionCounter";


const billings = [
    {
        plan: "super",
        label: "plan_chatGPT",
        students: 12,
        is_long_date: false,
        discount: null,
        price: {
            pre_order: 49.90,
            regular: 79.90,
        },
        plan_target_euro: "https://buy.stripe.com/bIY7uwfyaftz3BKfZ4",
        plan_target_usd: "https://buy.stripe.com/9AQcOQdq2817c8gbIM",
        includes: ["3_hour_training", "discover_gpt", "chatGPT_in_action", "getting_for_business"],
        team_size: "For non-technical students",
    },
    {
        plan: "hero",
        label: "plan_business_ai",
        students: 5,
        is_long_date: true,
        discount: 45,
        price: {
            pre_order: 149.90,
            regular: 279.50,
        },
        plan_target_euro: "https://buy.stripe.com/bIY7uw85Ibdj7S0fZ5",
        plan_target_usd: "https://buy.stripe.com/9AQcOQdq2bdjgowbIN",
        includes: [
            "10_hour_training",
            "discover_gpt",
            "discover_ai",
            "chatGPT_in_action",
            "discover_real_life_cases",
            "discover_our_companies",
            "personalized_chat_meeting",
            "presentation_networks",
            "support_process",
            "coding_module"],
        team_size: "From scratch to super-hero",
    }
]

const Plans = () => {
    const {t, i18n} = useTranslation();
    const [isSmall] = useState(true);
    const currentLanguage = i18n.language;

    return (
        <div className='plans'>
            <Container>
                <div className="custom-box">
                    <div className="title-area">
                        <h3>
                            {t("plans.title_plans")}
                        </h3>
                        <h6>
                            {t("plans.title_plans_optional")}
                        </h6>
                        <h4>
                            {t("plans.sub_title_plans")}
                        </h4>
                    </div>

                    {/*
                    <div className="billed-mode">
                        <div onClick={() => setIsSmall(true)} className={`mode ${isSmall ? 'active' : ''}`}>
                            Learn
                        </div>
                        <div onClick={() => setIsSmall(false)} className={`mode ${!isSmall ? 'active' : ''}`}>
                            Learn & Build 🚀
                        </div>
                    </div>
                    */}

                    <div className="plan-items-wrapper">
                        <Row className='justify-content-center'>
                            {billings.map((plan) =>
                                <Col md={6} xs={12} key={plan.label}>
                                    <SubsciptionCounter students={plan.students} isLongDate={plan.is_long_date}/>
                                    <div className='plan-item'>
                                        <div className="team-size">
                                            {plan.plan === "super" ? (
                                                <img src="./assets/images/patron.png" alt="boss"/>) : (
                                                <img src="./assets/images/bouclier.png" alt="boss"/>)}
                                            <span>{plan.team_size}</span>
                                        </div>

                                        <div className="title">
                                            <h5>{t(`plans.${plan.label}`)}</h5>
                                        </div>

                                        <div className="prices">
                                            <ul className="list-unstyled d-flex p-0 mb-0 align-items-center">
                                                    <li className={isSmall ? 'active' : ''}>
                                                        <b>{t("plans.currency")}&nbsp;{plan.price.pre_order}</b>
                                                    </li>
                                                {plan.discount !== null ?
                                                    (<li className={!isSmall ? 'active' : ''}>
                                                    <b>{t("plans.currency")}&nbsp;{plan.price.regular}</b>
                                                    </li>) : (<></>)}
                                                {plan.discount !== null ?
                                                    (<li className='discount-badge'>
                                                        {t("content.pre_launch")} {plan.discount}% {t("content.solve_word")}
                                                    </li>) : (<></>)}
                                            </ul>
                                        </div>

                                        {plan.includes.length > 0 && (
                                            <div className="includes">
                                                <ul className="list-unstyled p-0 m-0">
                                                    {plan.includes.map((inc) => <li key={inc}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M4 9.67472L10.8803 13.647C10.9543 13.6898 11.032 13.7207 11.1111 13.7403V21.4444L4.4907 17.5265C4.18655 17.3465 4 17.0193 4 16.6659V9.67472ZM20 9.56912V16.6659C20 17.0193 19.8134 17.3465 19.5093 17.5265L12.8889 21.4444V13.6728C12.905 13.6648 12.921 13.6562 12.9368 13.647L20 9.56912Z"
                                                                  fill="#5576E4"/>
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M4.21631 7.74671C4.30035 7.64058 4.40642 7.55089 4.53009 7.48502L11.5301 3.75667C11.8239 3.60015 12.1764 3.60015 12.4703 3.75667L19.4703 7.48502C19.5656 7.5358 19.6505 7.60073 19.7229 7.67643L12.0481 12.1075C11.9977 12.1366 11.9511 12.1699 11.9087 12.2068C11.8663 12.1699 11.8198 12.1366 11.7693 12.1075L4.21631 7.74671Z"
                                                                  fill="#F0F4FF"/>
                                                        </svg>

                                                        <span>{t(`plans.options.${inc}`)}</span>
                                                    </li>)}
                                                </ul>
                                            </div>
                                        )}

                                        <div className="action">
                                            <Button size='lg'
                                                    href={`${currentLanguage === 'fr' ? plan.plan_target_euro : plan.plan_target_usd}`}
                                                    className='w-100'>
                                                {t("content.get_started_button")}
                                            </Button>
                                            <div className="condition"><small>{t("content.condition_button")}</small>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            )}
                        </Row>
                    </div>

                </div>
            </Container>
        </div>
    )
}

export default Plans