import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


import Header from './components/Header';
import Footer from './components/Footer';

import HomePage from './routes/HomePage';
import Utils from './components/Utils';
import ConfirmPage from './routes/ConfirmPage';

import './App.scss';
import WhatsappChatBot from "./components/WhatsappChatBot";
import TOS from "./routes/TOS";
import Conference from "./routes/Conference";
import ConfirmBooking from "./routes/ConfirmBooking";

const router = createBrowserRouter([
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: 'confirm-payment',
        element: <ConfirmPage />
    },
    {
        path: 'tos',
        element: <TOS />
    },
    {
        path: 'conferences',
        element: <Conference />
    },
    {
        path: 'reservation-confirmation',
        element: <ConfirmBooking />
    }
])

function App() {
    const { i18n } = useTranslation()

    useEffect(() => {
        if (navigator.cookieEnabled && localStorage !== 'undefined') {
            let selectedTheme = localStorage.getItem(`${process.env.REACT_APP_STORAGE_PREFIX}_theme`);
            let selectedLanguage = localStorage.getItem(`${process.env.REACT_APP_STORAGE_PREFIX}_language`);

            if (selectedTheme)
                document.documentElement.setAttribute('data-bs-theme', selectedTheme)

            if (selectedLanguage) { }
            i18n.changeLanguage(selectedLanguage)

            window.history.replaceState(null, null, `?language=${Utils.LocaleNameMapper[selectedLanguage ? selectedLanguage : i18n.language]}`);
        }
    }, [i18n])

    return (
        <div className="App">
            <Header onClickThemeSwitcher={Utils.themeToggler} />
            <RouterProvider router={router} />
            <Footer />
            <WhatsappChatBot />
        </div>
    );
}

export default App;
