import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Features = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t('benefits.written_materials.title'),
            features: [
                t('benefits.written_materials.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 17.722C20.595 17.375 21 16.737 21 16V5C21 3.897 20.103 3 19 3H5C3.897 3 3 3.897 3 5V16C3 16.736 3.405 17.375 4 17.722V18H2V20H22V18H20V17.722ZM5 16V5H19L19.002 16H5Z" fill="#4167DA" />
                </svg>
        },
        {
            title: t('benefits.practical_exercises.title'),
            features: [
                t('benefits.practical_exercises.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 5V19H9V13H15V19H18V5H15V11H9V5H6ZM3 15C3 15.2652 3.10536 15.5196 3.29289 15.7071C3.48043 15.8946 3.73478 16 4 16H5V8H4C3.73478 8 3.48043 8.10536 3.29289 8.29289C3.10536 8.48043 3 8.73478 3 9V11H2V13H3V15ZM21 9C21 8.73478 20.8946 8.48043 20.7071 8.29289C20.5196 8.10536 20.2652 8 20 8H19V16H20C20.2652 16 20.5196 15.8946 20.7071 15.7071C20.8946 15.5196 21 15.2652 21 15V13H22V11H21V9Z" fill="#4167DA" />
                </svg>

        },
        {
            title: t('benefits.private_community.title'),
            features: [
                t('benefits.private_community.description'),
            ],
            image: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6H5C3.346 6 2 7.346 2 9V14C2 16.206 3.794 18 6 18H7.637C8.803 18 9.917 17.443 10.618 16.509C11.278 15.63 12.722 15.629 13.382 16.51C13.7301 16.9717 14.1804 17.3465 14.6976 17.605C15.2148 17.8635 15.7848 17.9987 16.363 18H18C20.206 18 22 16.206 22 14V9C22 7.346 20.654 6 19 6ZM20 14C20 15.103 19.103 16 18 16H16.363C15.823 16 15.306 15.741 14.981 15.31C14.271 14.362 13.184 13.818 12 13.818C10.816 13.818 9.729 14.362 9.019 15.309C8.85737 15.5228 8.64857 15.6964 8.40886 15.8162C8.16915 15.9361 7.905 15.999 7.637 16H6C4.897 16 4 15.103 4 14V9C4 8.449 4.448 8 5 8H19C19.552 8 20 8.449 20 9V14Z" fill="#4167DA" />
                <path d="M7.5 13C8.88071 13 10 12.3284 10 11.5C10 10.6716 8.88071 10 7.5 10C6.11929 10 5 10.6716 5 11.5C5 12.3284 6.11929 13 7.5 13Z" fill="#4167DA" />
                <path d="M16.5 13C17.8807 13 19 12.3284 19 11.5C19 10.6716 17.8807 10 16.5 10C15.1193 10 14 10.6716 14 11.5C14 12.3284 15.1193 13 16.5 13Z" fill="#4167DA" />
            </svg>
        },
        {
            title: t('benefits.live_webinars.title'),
            features: [
                t('benefits.live_webinars.description'),
            ],
            image: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C7.037 2 3 6.038 3 11C3 14.328 4.82 17.232 7.513 18.79L5.446 20.168C5.26749 20.287 5.132 20.4603 5.05954 20.6623C4.98707 20.8642 4.98148 21.0841 5.04358 21.2895C5.10569 21.4948 5.23219 21.6748 5.40442 21.8027C5.57664 21.9307 5.78545 21.9998 6 22H18C18.2146 22 18.4236 21.931 18.596 21.8032C18.7684 21.6753 18.895 21.4953 18.9573 21.2899C19.0195 21.0845 19.014 20.8645 18.9415 20.6625C18.8691 20.4604 18.7336 20.2871 18.555 20.168L16.488 18.79C19.18 17.232 21 14.328 21 11C21 6.038 16.963 2 12 2ZM12 18C8.141 18 5 14.859 5 11C5 7.14 8.141 4 12 4C15.859 4 19 7.14 19 11C19 14.859 15.859 18 12 18Z" fill="#4167DA" />
                <path d="M12 6C9.243 6 7 8.243 7 11C7 13.757 9.243 16 12 16C14.757 16 17 13.757 17 11C17 8.243 14.757 6 12 6ZM12 14C10.346 14 9 12.654 9 11C9 9.346 10.346 8 12 8C13.654 8 15 9.346 15 11C15 12.654 13.654 14 12 14Z" fill="#4167DA" />
            </svg>
        },
        {
            title: t('benefits.personalized_follow_up.title'),
            features: [
                t('benefits.personalized_follow_up.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 17.013L11.413 16.998L21.045 7.45802C21.423 7.08003 21.631 6.57802 21.631 6.04402C21.631 5.51002 21.423 5.00802 21.045 4.63002L19.459 3.04402C18.703 2.28802 17.384 2.29202 16.634 3.04102L7 12.583V17.013ZM18.045 4.45802L19.634 6.04102L18.037 7.62302L16.451 6.03802L18.045 4.45802ZM9 13.417L15.03 7.44402L16.616 9.03002L10.587 15.001L9 15.006V13.417Z" fill="#4167DA" />
                    <path d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z" fill="#4167DA" />
                </svg>

        },
        {
            title: t('benefits.updates_additional_content.title'),
            features: [
                t('benefits.updates_additional_content.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM4 19V7H20L20.002 19H4Z" fill="#4167DA" />
                    <path d="M9.29294 9.29297L5.58594 13L9.29294 16.707L10.7069 15.293L8.41394 13L10.7069 10.707L9.29294 9.29297ZM14.7069 9.29297L13.2929 10.707L15.5859 13L13.2929 15.293L14.7069 16.707L18.4139 13L14.7069 9.29297Z" fill="#4167DA" />
                </svg>

        },
        {
            title: t('benefits.completion_certificate.title'),
            features: [
                t('benefits.completion_certificate.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 2H8C6.897 2 6 2.897 6 4V16C6 17.103 6.897 18 8 18H20C21.103 18 22 17.103 22 16V4C22 2.897 21.103 2 20 2ZM8 16V4H20L20.002 16H8Z" fill="#4167DA" />
                    <path d="M4 8.00001H2V20C2 21.103 2.897 22 4 22H16V20H4V8.00001ZM12.933 11.519L11.207 9.79301L9.793 11.207L13.067 14.481L18.769 7.64101L17.231 6.35901L12.933 11.519Z" fill="#4167DA" />
                </svg>
        },
        {
            title: t('benefits.satisfaction_guarantee.title'),
            features: [
                t('benefits.satisfaction_guarantee.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 8H14.388L15.511 4.633C15.713 4.025 15.611 3.351 15.236 2.831C14.861 2.311 14.253 2 13.612 2H12C11.703 2 11.422 2.132 11.231 2.36L6.531 8H4C2.897 8 2 8.897 2 10V19C2 20.103 2.897 21 4 21H17.307C17.7139 20.9986 18.1108 20.8738 18.4452 20.6421C18.7797 20.4103 19.0359 20.0825 19.18 19.702L21.937 12.351C21.9789 12.2387 22.0002 12.1198 22 12V10C22 8.897 21.103 8 20 8ZM4 10H6V19H4V10ZM20 11.819L17.307 19H8V9.362L12.468 4H13.614L12.052 8.683C12.0013 8.83331 11.9871 8.99355 12.0107 9.15043C12.0343 9.3073 12.095 9.45629 12.1877 9.58504C12.2803 9.71379 12.4024 9.8186 12.5436 9.89076C12.6849 9.96293 12.8414 10.0004 13 10H20V11.819Z" fill="#4167DA" />
                </svg>

        },
        {
            title: t('benefits.discounts_special_offers.title'),
            features: [
                t('benefits.discounts_special_offers.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.707 3.293C13.6143 3.19996 13.5041 3.12617 13.3828 3.07589C13.2614 3.0256 13.1313 2.99981 13 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V13C3 13.266 3.105 13.52 3.293 13.707L11.293 21.707C11.3857 21.8002 11.4958 21.8741 11.6171 21.9246C11.7385 21.9751 11.8686 22.001 12 22.001C12.1314 22.001 12.2615 21.9751 12.3829 21.9246C12.5042 21.8741 12.6143 21.8002 12.707 21.707L21.707 12.707C21.7999 12.6142 21.8737 12.504 21.924 12.3827C21.9743 12.2614 22.0002 12.1313 22.0002 12C22.0002 11.8687 21.9743 11.7386 21.924 11.6173C21.8737 11.496 21.7999 11.3858 21.707 11.293L13.707 3.293ZM12 19.586L5 12.586V5H12.586L19.586 12L12 19.586Z" fill="#4167DA" />
                    <path d="M8.49621 9.99999C9.3274 9.99999 10.0012 9.32618 10.0012 8.49499C10.0012 7.6638 9.3274 6.98999 8.49621 6.98999C7.66502 6.98999 6.99121 7.6638 6.99121 8.49499C6.99121 9.32618 7.66502 9.99999 8.49621 9.99999Z" fill="#4167DA" />
                </svg>

        },
        {
            title: t('benefits.testimonials_success_stories.title'),
            features: [
                t('benefits.testimonials_success_stories.description'),
            ],
            image:
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 18V21.766L6.515 20.857L11.277 18H16C17.103 18 18 17.103 18 16V8C18 6.897 17.103 6 16 6H4C2.897 6 2 6.897 2 8V16C2 17.103 2.897 18 4 18H5ZM4 8H16V16H10.723L7 18.234V16H4V8Z" fill="#4167DA" />
                    <path d="M20 2H8C6.897 2 6 2.897 6 4H18C19.103 4 20 4.897 20 6V14C21.103 14 22 13.103 22 12V4C22 2.897 21.103 2 20 2Z" fill="#4167DA" />
                </svg>

        },
    ]

    return (
        <div className='features'>
            <Container>
                <div className="title-area">
                    <h3>
                        {t("benefits.title")}
                    </h3>
                    <h4>
                        {t("benefits.sub_title")}
                    </h4>
                </div>

                <div className="feature-items-wrapper">
                    <Row className='g-sm-5 g-4'>
                        {items.map((item) => <Col lg={3} md={4} sm={4} xs={12} key={item.title}>
                            <div className="feature-item">
                                <div className="icon-box">
                                    {item.image}
                                </div>
                                <div className="content">
                                    <h6>
                                        {item.title}
                                    </h6>
                                    <p className='mb-0'>
                                        {item.features}
                                    </p>
                                </div>
                            </div>
                        </Col>)}
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Features