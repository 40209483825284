import { useContext } from 'react';
import { Container, Row, Col, Accordion, useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <>
            {isCurrentEventKey ? (
                <div
                    style={{ cursor: 'pointer' }}
                    className="w-100 d-flex align-items-center justify-content-between"
                    onClick={decoratedOnClick}
                >
                    {children}
                    <span className='toggle-icon' style={{ color: "rgba(255, 102, 0, 0.911)" }}>
                        <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H14V2H0V0Z" fill="#02264C" />
                        </svg>
                    </span>
                </div>
            ) : (
                <div
                    style={{ cursor: 'pointer' }}
                    className="w-100 d-flex align-items-center justify-content-between"
                    onClick={decoratedOnClick}
                >
                    {children}
                    <span className='toggle-icon' style={{ color: "rgba(255, 102, 0, 0.911)" }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" fill="#02264C" />
                        </svg>
                    </span>
                </div>
            )}
        </>
    );
}

const FAQ = () => {
    const { t } = useTranslation()

    const questions = [
        {
            title: t('faq.faq_item_1_question'),
            description: t('faq.faq_item_1_answer')
        },


        {
            title: t('faq.faq_item_2_question'),
            description: t('faq.faq_item_2_answer')
        },


        {
            title: t('faq.faq_item_3_question'),
            description: t('faq.faq_item_3_answer')
        },


        {
            title: t('faq.faq_item_4_question'),
            description: t('faq.faq_item_4_answer')
        },


        {
            title: t('faq.faq_item_5_question'),
            description: t('faq.faq_item_5_answer')
        },


        {
            title: t('faq.faq_item_6_question'),
            description: t('faq.faq_item_6_answer')
        },


    ]

    return (
        <section className="faq d-flex py-sm-5 py-0">
            <Container>
                <div className="title-area">
                    <h3 className='fs-1'>
                        {t('faq.faq_title')}
                    </h3>
                    <h4>
                        {t("faq.sub_title")}
                    </h4>
                </div>

                <Row className="justify-content-center mt-5">
                    <Col lg={10}>
                        <Accordion>
                            {questions.map((question, index) => (
                                <div className="accordion-item mb-3" key={index}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <ContextAwareToggle eventKey={index} callback={undefined}>
                                            <h3 className='fs-6'>{question.title}</h3>
                                        </ContextAwareToggle>
                                    </div>
                                    <Accordion.Collapse eventKey={index}>
                                        <div className="accordion-content">
                                            <p className="mb-0">{question.description}</p>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default FAQ;
