import React from 'react'
import {Container} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'

const TOS = () => {
    const {t} = useTranslation();

    return (
        <div className='tos-page'>
            <Container>
                <div>
                    <h1>{t('cgv.title')}</h1>
                    <h2>{t('cgv.preamble.title')}</h2>
                    <p>{t('cgv.preamble.content')}</p>
                    <h2>{t('cgv.article1.title')}</h2>
                    <p>{t('cgv.article1.content')}</p>
                    <h2>{t('cgv.article2.title')}</h2>
                    <p>{t('cgv.article2.content')}</p>
                    <h2>{t('cgv.article3.title')}</h2>
                    <p>{t('cgv.article3.content')}</p>
                    <h2>{t('cgv.article4.title')}</h2>
                    <p>{t('cgv.article4.content')}</p>
                    <h2>{t('cgv.article5.title')}</h2>
                    <p>{t('cgv.article5.content')}</p>
                    <h2>{t('cgv.article6.title')}</h2>
                    <p>{t('cgv.article6.content')}</p>
                    <h2>{t('cgv.article7.title')}</h2>
                    <p>{t('cgv.article7.content')}</p>
                    <h2>{t('cgv.article8.title')}</h2>
                    <p>{t('cgv.article8.content')}</p>
                </div>
            </Container>
        </div>
    )
}

export default TOS