import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import intro_logo from "../image/innovative_mind_intro.png"
import image_application from "../image/innovative_mind_application.png"
import image_bull from "../image/innovative_mind_bull.png"
import image_ethic from "../image/innovative_mind_ethic.png"
import image_respect from "../image/innovative_mind_respect.png"
import image_update from "../image/innovative_mind_update.png"
import image_trend from "../image/innovative_mind_trend.png"
import image_share from "../image/innovative_mind_share.png"

const Courses = () => {
    const { t } = useTranslation()

    const items = [
        {
            title: t('content.section_1_title'),
            features: [
                t('content.section_1_item_1'),
                t('content.section_1_item_2'),
            ],
            badge: t('content.section_1_badge'),
            image: intro_logo,
            image_fill_color: "#61dae8"
        },
        {
            title: t('content.section_2_title'),
            features: [
                t('content.section_2_item_1'),
                t('content.section_2_item_2'),
                t('content.section_2_item_3'),
            ],
            badge: t('content.section_2_badge'),
            image: image_application,
            image_fill_color: "#ca627e"
        },
        {
            title: t('content.section_3_title'),
            features: [
                t('content.section_3_item_1'),
                t('content.section_3_item_2'),
            ],
            badge: t('content.section_3_badge'),
            image: image_bull,
            image_fill_color: "#120a2b"
        },
        {
            title: t('content.section_4_title'),
            features: [
                t('content.section_4_item_1'),
                t('content.section_4_item_2'),
            ],
            badge: t('content.section_4_badge'),
            image: image_ethic,
            image_fill_color: "#ca57bd"
        },
        {
            title: t('content.section_5_title'),
            features: [
                t('content.section_5_item_1'),
                t('content.section_5_item_2'),
                t('content.section_5_item_3'),
            ],
            badge: t('content.section_5_badge'),
            image: image_respect,
            image_fill_color: "#171616"
        },
        {
            title: t('content.section_6_title'),
            features: [
                t('content.section_6_item_1'),
                t('content.section_6_item_2'),
                t('content.section_6_item_3'),
            ],
            badge: t('content.section_6_badge'),
            image: image_update,
            image_fill_color: "#e4443e"
        },
        {
            title: t('content.section_7_title'),
            features: [
                t('content.section_7_item_1'),
                t('content.section_7_item_2'),
            ],
            badge: t('content.section_7_badge'),
            image: image_share,
            image_fill_color: "#261a16"
        },
        {
            title: t('content.section_8_title'),
            features: [
                t('content.section_8_item_1'),
                t('content.section_8_item_2'),
            ],
            badge: t('content.section_8_badge'),
            image: image_trend,
            image_fill_color: "#c46c28"
        },

    ]

    return (
        <div className='courses'>
            <Container>
                <div className="title-area">
                    <h3>
                        {t("content.title")}
                    </h3>
                    <h4>
                        {t("content.sub_title")}
                    </h4>
                </div>

                <div className="courses-wrapper">
                    <Row className='g-3'>
                        {items.map((item) => <Col lg={3} md={6} sm={6} xs={12} key={item.title}>
                            <div className="course-item" key={item.title}>
                                <div className="image-area" style={{backgroundColor: item.image_fill_color}}>
                                    <img src={item.image} alt=""/>
                                    <div className="duration">
                                        {item.badge}
                                    </div>
                                </div>
                                <div className="content-area">
                                    <h6>{item.title}</h6>

                                    <ul className="mb-0">
                                        {item.features.map((f) => <li key={f}>{f}</li>)}
                                    </ul>
                                </div>
                            </div>
                        </Col>)}
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Courses