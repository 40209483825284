import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import ReactHtmlParser from 'react-html-parser';


const WhyMe = () => {
    const {t} = useTranslation()

    return (
        <div className='whyme-gpt'>
            <Container>
                <div className="custom-box">
                    <Row className='g-3'>
                        <Col lg={8} xs={12}>
                            <div className="content-area">
                                <h5 className="text-center mb-5">
                                    {t('whyme.title')}
                                </h5>
                                <p>{ReactHtmlParser(t('whyme.q1'))}</p>
                                <p>{ReactHtmlParser(t('whyme.q2'))}</p>
                                <p>{ReactHtmlParser(t('whyme.q3'))}</p>
                                <p>{ReactHtmlParser(t('whyme.q4'))}</p>
                                <p>{ReactHtmlParser(t('whyme.q5'))}</p>
                                <p className="text-center mt-5">{ReactHtmlParser(t('whyme.answer_global'))}</p>
                            </div>
                        </Col>
                        <Col lg={4} xs={12} className="my-auto">
                            <div className="content-area">
                                <h5 className="text-center then">
                                    {t('whyme.accroch_1')}
                                </h5>
                                <h5 className="text-center">
                                    {t('whyme.accroch_2')}
                                </h5>
                                <h5 className="text-center">
                                    <span className="for-you">{t('whyme.accroch_3')}</span>
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default WhyMe