const LocaleNameMapper = {
    en: "English",
    fr: "French"
}

const themeToggler = () => {
    let currentTheme = document.documentElement.getAttribute('data-bs-theme');

    if (currentTheme === 'dark') {
        document.documentElement.removeAttribute('data-bs-theme')

        if (navigator.cookieEnabled && localStorage !== 'undefined')
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_theme`, 'light')
    }
    else {
        document.documentElement.setAttribute('data-bs-theme', 'dark')

        if (navigator.cookieEnabled && localStorage !== 'undefined')
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_theme`, 'dark')
    }
}

const languageToggler = (language) => {
    if (navigator.cookieEnabled && localStorage !== 'undefined') {
        localStorage.setItem(`${process.env.REACT_APP_STORAGE_PREFIX}_language`, language);
    }
}

const Utils = {
    LocaleNameMapper,
    themeToggler,
    languageToggler
}

export default Utils;