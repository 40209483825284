import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Swiper, SwiperSlide } from "swiper/react";
import ReactHtmlParser from 'react-html-parser';


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import { Navigation, FreeMode, Thumbs } from "swiper";

const Testimonial = () => {
    const { t } = useTranslation()

    const comments = [
        {
            title: t('testimonial.testimonial_1_text'),
            description: t('testimonial.testimonial_1_name')

        },

        {
            title: t('testimonial.testimonial_2_text'),
            description: t('testimonial.testimonial_2_name')

        },


        {
            title: t('testimonial.testimonial_3_text'),
            description: t('testimonial.testimonial_3_name')

        },

        {
            title: t('testimonial.testimonial_4_text'),
            description: t('testimonial.testimonial_4_name')

        },

        {
            title: t('testimonial.testimonial_5_text'),
            description: t('testimonial.testimonial_5_name')

        },

        {
            title: t('testimonial.testimonial_6_text'),
            description: t('testimonial.testimonial_6_name')

        },

        {
            title: t('testimonial.testimonial_7_text'),
            description: t('testimonial.testimonial_7_name')

        },

        {
            title: t('testimonial.testimonial_8_text'),
            description: t('testimonial.testimonial_8_name')
        },

        {
            title: t('testimonial.testimonial_9_text'),
            description: t('testimonial.testimonial_9_name')
        },



        {
            title: t('testimonial.testimonial_10_text'),
            description: t('testimonial.testimonial_10_name')

        },

        {
            title: t('testimonial.testimonial_11_text'),
            description: t('testimonial.testimonial_11_name')

        },


        {
            title: t('testimonial.testimonial_12_text'),
            description: t('testimonial.testimonial_12_name')

        },


    ]

    const [my_swiper, set_my_swiper] = useState();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [position, setPosition] = useState({
        isEnd: false,
        isBegining: true,
    });


    return (
        <div className='testimonial'>
            <Container>
                <div className="title-area">
                    <h3>
                        {t('testimonial_main_title')}
                    </h3>
                    {/* <h4>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </h4> */}
                </div>
                <div className='testimonial-wrapper'>
                    <Row className='align-items-center'>
                        <Col md={2}>
                            <button
                                className="action-btn"
                                disabled={position.isBegining}
                                onClick={() => {
                                    my_swiper?.slidePrev()
                                }}
                            >

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.2929 6.29297L7.58594 12L13.2929 17.707L14.7069 16.293L10.4139 12L14.7069 7.70697L13.2929 6.29297Z" fill="#02264C" />
                                </svg>
                            </button>
                        </Col>
                        <Col md={8}>
                            <Swiper
                                loop={true}
                                pagination={false}
                                navigation={false}
                                modules={[FreeMode, Navigation, Thumbs]}
                                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                className="mySwiper2"
                                onSlideChange={(item) => {
                                    if (item.isEnd) {
                                        setPosition({
                                            isEnd: true,
                                            isBegining: false,
                                        });
                                    } else if (item.isBeginning) {
                                        setPosition({
                                            isEnd: false,
                                            isBegining: true,
                                        });
                                    } else {
                                        setPosition({
                                            isEnd: false,
                                            isBegining: false,
                                        });
                                    }
                                }}
                                onSwiper={set_my_swiper}
                            >
                                {comments.map((el, i) => <SwiperSlide key={el.title}>
                                    {ReactHtmlParser(el.title)}
                                </SwiperSlide>)}
                            </Swiper>

                            <Swiper
                                // loop={true}
                                spaceBetween={0}
                                slidesPerView={3.2}
                                freeMode={true}
                                // watchSlidesProgress={true}
                                onSwiper={setThumbsSwiper}
                                className="mySwiper"
                                modules={[FreeMode, Navigation, Thumbs]}
                            >
                                {comments.map((el, i) => <SwiperSlide key={el.description}>
                                    <div className="thumbs">
                                        <h5>
                                            {el.description.split(',')[0]}
                                        </h5>
                                        <h6>
                                            {el.description.split(',')[1]}
                                        </h6>
                                    </div>
                                </SwiperSlide>)}
                            </Swiper>
                        </Col>
                        <Col md={2} className='d-flex justify-content-end'>
                            <button
                                className="action-btn"
                                disabled={position.isEnd}
                                onClick={() => {
                                    my_swiper?.slideNext()
                                }}
                            >

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.707 17.707L16.414 12L10.707 6.29297L9.29297 7.70697L13.586 12L9.29297 16.293L10.707 17.707Z" fill="#02264C" />
                                </svg>
                            </button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Testimonial