import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import image_mascot from "../image/innovative_mind_mascot.png"

const Why = () => {
    const { t } = useTranslation()

    return (
        <div className='why-gpt'>
            <Container>
                <div className="custom-box">
                    <Row className='g-3'>
                        <Col lg={6} xs={12}>
                            <div className="content-area">
                                <h5>
                                    {t('content.why_choose_title')}
                                </h5>
                                <p>{t('content.why_choose_paragraph_1')}</p>
                                <p>{t('content.why_choose_paragraph_2')}</p>
                                <p>{t('content.why_choose_paragraph_3')}</p>
                            </div>
                        </Col>
                        <Col lg={6} xs={12}>
                            <div className="video-area">
                                    <img src={image_mascot} alt={image_mascot}/>
                            </div>
                        </Col>

                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Why