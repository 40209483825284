import Banner from '../components/Banner';
import Plans from '../components/Plans';
import Features from '../components/Features';
import Courses from '../components/Courses';
import Why from '../components/Why';
import WhyMe from "../components/WhyMe";
import FAQ from '../components/FAQ';
import Testimonial from '../components/Testimonial';


const HomePage = () => {
    return (
        <div className='home-page'>
            <Banner />
            <Plans />
            <Features />
            <Courses />
            <Why />
            <WhyMe />
            <Testimonial />
            <FAQ />
        </div>
    )
}

export default HomePage