import React, { useEffect, useState } from 'react'
import { Button, Container, Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Utils from './Utils';
import { BsGlobe2 } from 'react-icons/bs';

const Header = ({ onClickThemeSwitcher }) => {
    const { t, i18n } = useTranslation();

    const [locale, setLocale] = useState(Utils.LocaleNameMapper[i18n.language])

    const handleChangeLocale = (locale) => {
        setLocale(Utils.LocaleNameMapper[locale])

        Utils.languageToggler(locale)

        i18n.changeLanguage(locale)
    }

    useEffect(() => {
        setLocale(Utils.LocaleNameMapper[i18n.language])

        window.history.replaceState(null, null, `?language=${Utils.LocaleNameMapper[i18n.language]}`);
    }, [i18n.language])


    return (
        <header>
            <Container>
                <div className="header-wrapper">
                    <div className="logo">
                        <a href="/">
                            <img src="assets/images/logo.png" alt="ChatGPT Training" className='img-fluid' />
                            <div className="logo-content d-sm-block d-none">
                                <div className="title">
                                    AI-In-Business
                                </div>
                                <div className="sub-title">
                                    Teaching the power of AI
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="locale-theme ms-auto">
                    <Button variant="primary" href="/conferences" className="ms-3">
                        {t("booking.header")}
                    </Button>
                        <div className="locale-changer">
                            <Dropdown>
                                <Dropdown.Toggle className='p-0' variant='' id='locale-changer'>
                                    <BsGlobe2 />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {locale === 'English' ? (
                                        <Dropdown.Item onClick={() => handleChangeLocale('fr')}>
                                            <img src="https://flagcdn.com/fr.svg" className="flag" alt="French"/> Francais
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item onClick={() => handleChangeLocale('en')}>
                                            <img src="https://flagcdn.com/gb.svg" className="flag" alt="English"/> English
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="theme-changer">
                            <img src="assets/images/DarkMode.svg" alt="toggle theme color" onClick={onClickThemeSwitcher} />
                        </div>
                    </div>
                </div>
            </Container>
        </header>
    )
}

export default Header