import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import htmlParser from 'react-html-parser'
import cotillons from '../image/cotillon.png';
import cardError from '../image/card_error.png';

const ConfirmBooking = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [query] = useSearchParams();

    useEffect(() => {
        if (!query.has('status') || !['success', 'failed'].includes(query.get('status')))
            navigate('/', { replace: true })

    }, [query, navigate])

    const paymentStatus = query.get('status');
    let content;

    if (paymentStatus === 'failed')
        content = <div className="failed">
            <img src={cardError} alt="error_img" className='center'/>
            <h1 className='fs-3 fw-bold text-center mb-3 text-danger'>{t("confirmBooking.failed.title")}</h1>
            {htmlParser(t("confirmBooking.failed.details"))}
        </div>
    else
        content = <div className="success">
            <img src={cotillons} alt="success_img" className='align-center'/>
            <h1 className='fs-3 fw-bold text-center mb-3 text-success'>{t("confirmBooking.success.title")}</h1>
            {htmlParser(t("confirmBooking.success.details"))}
        </div>

    return (
        <div className='confirm-page'>
            <Container>
                <div className="shadow-md custom-box my-5 p-lg-5 p-4">
                    {content}
                </div>
            </Container>
        </div>
    )
}

export default ConfirmBooking