import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";

const SubsciptionCounter = (props) => {
    const {t} = useTranslation();
    const [inscrits, setInscrits] = useState(0);
    const longDate = props.isLongDate;

    useEffect(() => {
        const dateDebut = new Date("2023-05-03");
        const aujourdHui = new Date();

        const diffJours = Math.ceil(Math.abs(aujourdHui - dateDebut) / (1000 * 60 * 60 * 24));

        const initialInscrits = 54;
        const inscritsParJour = props.students;

        const totalInscrits = initialInscrits + diffJours * inscritsParJour;

        setInscrits(totalInscrits);
    }, [props.students]);

    return (
        <div className="text-center mb-2">
            {longDate ? (<small><b>{t("timer.subscriber_long")}&nbsp;{inscrits}&nbsp;{t("timer.student")}</b></small>) :
                //(<small><b>{t("timer.subscriber_short")}&nbsp;{inscrits}&nbsp;{t("timer.student")}</b></small>)}
                (<>&nbsp;</>)}
        </div>
    );
};

export default SubsciptionCounter;