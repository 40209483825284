import React, {useState, useEffect, useCallback} from 'react';
import {Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from "react-i18next";

const Timer = (props) => {
    const {t} = useTranslation();
    const calculateTimeLeft = useCallback(() => {
        let difference = +new Date(props.dateCible) - +new Date();
        let timeLeft = {};

        if (difference <= 0) {
            const hourAfterZero = Math.abs(difference) - 3600000; // 3600000 milliseconds = 1 hour
            if (hourAfterZero >= 0) {
                var futureDate = new Date();
                futureDate.setDate(futureDate.getDate() + 5);
                props.onDateChange(futureDate.toISOString());
                difference = +new Date(props.dateCible) - +new Date();
            } else {
                return timeLeft;
            }
        }

        timeLeft = {
            jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
            heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            secondes: Math.floor((difference / 1000) % 60),
        };
        return timeLeft;
    }, [props])

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    return (
        <Container>
            <Row className="timer row justify-content-center align-items-center text-center">
                <Col md={3} xs={3} lg={3}>
                    <div className="bloc-timer mx-2">
                        <div className="nombre">{timeLeft.jours}</div>
                        <div className="label">{t("timer.days")}</div>
                    </div>
                </Col>
                <Col md={3} xs={3} lg={3}>
                    <div className="bloc-timer mx-2">
                        <div className="nombre">{timeLeft.heures}</div>
                        <div className="label">{t("timer.hours")}</div>
                    </div>
                </Col>
                <Col md={3} xs={3} lg={3}>
                    <div className="bloc-timer mx-2">
                        <div className="nombre">{timeLeft.minutes}</div>
                        <div className="label">{t("timer.min")}</div>
                    </div>
                </Col>
                <Col md={3} xs={3} lg={3}>
                    <div className="bloc-timer mx-2">
                        <div className="nombre">{timeLeft.secondes}</div>
                        <div className="label">{t("timer.sec")}</div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Timer;
