import React, {useState} from 'react';
import {Container, ListGroup, Button, Modal, Badge} from 'react-bootstrap';
import {
    BsClock,
    BsGeoAlt,
    BsPeopleFill,
    BsFillCalendarCheckFill,
    BsShare,
    BsCalendar3,
    BsDoorClosedFill,
    BsDoorOpen,
    BsFillStarFill
} from 'react-icons/bs';
import {useTranslation} from 'react-i18next';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';


const CONFERENCES = [
    {
        id: 1,
        title: "Fonder une Startup IA : Parcours et Conseils",
        description: "Plongez-vous dans les réalités de la création d'une startup IA, de la levée de fonds à l'assemblage d'une équipe forte, tout en naviguant dans le paysage réglementaire.",
        date: "18/09/2023",
        time: "10:00",
        duration: "2 heures",
        location: "Restaurant l'hippodrome - Nancy France",
        availableSeats: 50,
        tags: ["Startup", "IA", "Business", "Levée de fonds", "Réglementation"],
        reservationStats: "OPEN",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 2,
        title: "Utiliser l'IA pour Propulser l'Innovation en Startup",
        description: "Découvrez comment les startups exploitent l'intelligence artificielle pour stimuler l'innovation, créer des avantages concurrentiels et révolutionner des industries.",
        date: "04/10/2023",
        time: "14:00",
        duration: "3 heures",
        location: "Le Royal Hotels & Resorts - Luxembourg Luxembourg",
        availableSeats: 40,
        tags: ["Startup", "IA", "Innovation", "Avantage concurrentiel", "Transformation"],
        reservationStats: "NOT_OPEN",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 3,
        title: "Exploiter l'IA pour la Création de Jeux Lucratifs",
        description: "Découvrez comment l'IA est utilisée pour améliorer l'expérience de jeu, créer des personnages non-joueurs convaincants et des mondes virtuels dynamiques. Apprenez comment vous pouvez transformer ces innovations en une entreprise rentable.",
        date: "21/06/2023",
        time: "11:00",
        duration: "3 heures",
        location: "Niort Tech - Niort France",
        availableSeats: 40,
        tags: ["IA", "Jeux vidéo", "Business", "Monétisation", "Innovation"],
        reservationStats: "FULL",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 4,
        title: "Déboguer et tester les systèmes d'IA",
        description: "Découvrez les défis uniques et les approches nécessaires pour déboguer et tester efficacement les systèmes basés sur l'IA.",
        date: "24/05/2023",
        time: "10:00",
        duration: "2 heures",
        location: "Mövenpick Hôtel & Casino Genève - Genève Suisse",
        availableSeats: 30,
        tags: ["avancé", "IA", "Débogage"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 5,
        title: "L'IA et le Big Data pour les Business Models Innovants",
        description: "Comprendre comment l'IA et le Big Data peuvent s'associer pour transformer votre business model, créer des solutions novatrices et se démarquer dans le marché.",
        date: "17/04/2023",
        time: "09:30",
        duration: "2 heures",
        location: "Centre des sciences - Nantes France",
        availableSeats: 35,
        tags: ["IA", "Big Data", "Business Model", "Innovation", "Marché"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 6,
        title: "Transformer le Secteur de la Santé avec l'IA",
        description: "Examinez comment l'IA révolutionne le secteur de la santé, en améliorant les diagnostics, la recherche et le traitement. Apprenez comment lancer une startup dans ce domaine.",
        date: "16/03/2023",
        time: "13:30",
        duration: "2 heures",
        location: "Université de Strasbourg - Strasbourg France",
        availableSeats: 90,
        tags: ["IA", "Santé", "Startup", "Diagnostics", "Recherche"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 7,
        title: "Les enjeux éthiques de l'IA",
        description: "Un débat sur les enjeux éthiques de l'intelligence artificielle et la manière dont nous devons réglementer cette technologie.",
        date: "30/02/2023",
        time: "19:00",
        duration: "2 heures",
        location: "Centre culturel - Toulouse France",
        availableSeats: 50,
        tags: ["tous niveaux", "IA", "Éthique"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 8,
        title: "L'avenir du Deep Learning",
        description: "Une discussion sur l'avenir du deep learning et son impact potentiel sur notre société.",
        date: "22/08/2023",
        time: "16:00",
        duration: "2 heures",
        location: "Centre des sciences - Marseille France",
        availableSeats: 45,
        tags: ["avancé", "Deep Learning"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 9,
        title: "Comprendre le Machine Learning",
        description: "Une exploration du machine learning, ses algorithmes et ses utilisations dans divers secteurs.",
        date: "15/08/2023",
        time: "14:00",
        duration: "3 heures",
        location: "Tech Campus - Lyon France",
        availableSeats: 70,
        tags: ["intermédiaire", "Machine Learning"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
    {
        id: 10,
        title: "Introduction à l'Intelligence Artificielle",
        description: "Une introduction approfondie à l'intelligence artificielle, ses principes et ses applications.",
        date: "15/08/2022",
        time: "10:00",
        duration: "2 heures",
        location: "Université de Paris - Paris France",
        availableSeats: 100,
        tags: ["débutant", "IA"],
        reservationStats: "CLOSED",
        paymentLink: "https://buy.stripe.com/dR65mo5XA5SZ4FO7sA"
    },
];


const ConferenceItem = ({conference, onSelect, onShare}) => {
    const ticketStyle = {
        border: '1px solid #ccc',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '20px',
        marginBottom: '20px',
        backgroundColor: '#f9f9f9',
        position: 'relative',
        overflow: 'hidden'
    };

    const ticketNotchStyle = {
        position: 'absolute',
        top: '0',
        right: '-10px',
        width: '20px',
        height: '20px',
        backgroundColor: '#fff',
        boxShadow: '-5px 5px 10px rgba(0, 0, 0, 0.15)',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%)'
    };

    const shareButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: '#000'
    };

    const shareModalStyle = {
        color: '#000'
    };

    const starStyle = {
        color: '#FFD700',
        bodyColor: 'grey'
    };

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const {t} = useTranslation();

    const handleShareClick = () => {
        setIsShareModalOpen(true);
    }

    const handleShareClose = () => {
        setIsShareModalOpen(false);
    }

    const handleBooking = () => {
        window.location.href = conference.paymentLink;
    }

    return (
        <ListGroup.Item style={ticketStyle} className="mb-4">
            <div style={ticketNotchStyle}></div>
            <Button variant="outline-secondary" style={shareButtonStyle} onClick={handleShareClick}><BsShare/></Button>
            <Modal show={isShareModalOpen} onHide={handleShareClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={shareModalStyle}>{t("booking.share_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {onShare(conference)}
                </Modal.Body>
            </Modal>

            <div>
                {conference.reservationStats === "OPEN" ? <><BsFillStarFill style={starStyle}/><BsFillStarFill
                    style={starStyle}/><BsFillStarFill style={starStyle}/></> : <></>}<h3
                className="mb-2">{conference.title}</h3>
                <p className="mb-2">{conference.description}</p>
                <p className="mb-2">
                    <BsClock/>&nbsp;{conference.date}&nbsp;{t("booking.at")}&nbsp;{conference.time}&nbsp;({conference.duration})
                </p>
                <p className="mb-2"><BsGeoAlt/>&nbsp;{conference.location}</p>
                <p className="mb-2"><BsPeopleFill/>&nbsp;{conference.availableSeats}&nbsp;{t("booking.free_place")}</p>
                {conference.tags.map(tag => <Badge variant="secondary" className="mr-2 mb-2">{tag}</Badge>)}
            </div>
            <div className="d-flex justify-content-center align-items-center">
                {(() => {
                    switch (conference.reservationStats) {
                        case "FULL":
                            return <Button size="lg" variant="secondary" className="mt-3"
                            ><BsCalendar3/>&nbsp;<small>{t("booking.full")}</small>
                            </Button>
                        case "NOT_OPEN":
                            return <Button size="lg" variant="secondary" className="mt-3"
                            ><BsDoorOpen/>&nbsp;<small>{t("booking.soon")}</small>
                            </Button>
                        case "CLOSE":
                            return <Button size="lg" variant="warning" className="mt-3"
                            ><BsDoorClosedFill/>&nbsp;<small>{t("booking.closed")}</small>
                            </Button>
                        case "OPEN":
                            return <Button size="lg" variant="success" className="mt-3"
                                           onClick={handleBooking}><BsFillCalendarCheckFill/>&nbsp;<small>{t("booking.book")}</small>
                            </Button>
                        default:
                            return <Button disabled size="lg"
                                           className="mt-3"><small>{t("booking.reservation_not_available")}</small></Button>
                    }
                })()}
            </div>
        </ListGroup.Item>
    )
}


const ConferenceList = ({shareConference}) => {
    return (
        <>
            <ListGroup>
                {CONFERENCES.map(conference =>
                    <ConferenceItem key={conference.id} conference={conference}
                                    onShare={shareConference}/>
                )}
            </ListGroup>
        </>
    )
}


const Conference = () => {
    const {t} = useTranslation();

    const shareConference = (conference) => {
        const shareUrl = window.location.href;
        const title = `${t("booking.share_title")} : ${conference.title} - ${conference.description}`;

        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <FacebookShareButton url={shareUrl} quote={title} className="share-button">
                    <FacebookIcon size={32} round={true}/>
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl} title={title} className="share-button">
                    <TwitterIcon size={32} round={true}/>
                </TwitterShareButton>

                <LinkedinShareButton url={shareUrl} title={title} className="share-button">
                    <LinkedinIcon size={32} round={true}/>
                </LinkedinShareButton>

                <EmailShareButton url={shareUrl} subject={title} body={t("booking.email_intro")}
                                  className="share-button">
                    <EmailIcon size={32} round={true}/>
                </EmailShareButton>

                <WhatsappShareButton url={shareUrl} title={title} separator=" " className="share-button">
                    <WhatsappIcon size={32} round={true}/>
                </WhatsappShareButton>
            </div>
        )
    }

    return (
        <div className='conferences'>
            <Container>
                <h1 className='mb-4'>{t('booking.conferences')}</h1>
                <div className="description">
                    <p>{t("booking.description.p1")}</p>
                    <p>{t("booking.description.p2")}</p>
                    <p>{t("booking.description.p3")}</p>
                    <p>{t("booking.description.p4")}</p>
                </div>
                <ConferenceList shareConference={shareConference}/>
            </Container>
        </div>
    )
}

export default Conference;