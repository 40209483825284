import React, {useEffect, useState} from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import Timer from "./Timer";

const Banner = () => {
    const {t} = useTranslation();
    const calculateNextDate = () => {
        const now = new Date();
        const day = now.getDate();

        let nextDate = day >= 30 ? 5 : ((Math.floor(day / 5) + 1) * 5);
        if (nextDate <= day) {
            nextDate += 5;
        }

        const futureDate = new Date();
        futureDate.setDate(nextDate);
        futureDate.setHours(23, 59, 59);
        return futureDate.toISOString();
    };

    const storedDate = localStorage.getItem('dateCible');
    const [dateCible, setDateCible] = useState(storedDate || calculateNextDate());

    const handleDateChange = (newDate) => {
        setDateCible(newDate);
    };

    useEffect(() => {
        localStorage.setItem('dateCible', dateCible);
    }, [dateCible]);

    return (
        <div className='banner'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={7} md={9} sm={11} xs={12}>
                        <h1>
                            {t("header.header_title_1")}
                        </h1>
                        <h2>
                            {t("header.header_title_2")}
                        </h2>
                        <Timer dateCible={dateCible} onDateChange={handleDateChange} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Banner
