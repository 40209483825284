import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import htmlParser from 'react-html-parser'

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="custom-box rounded-0">
            <Container>
                <Row className="g-4">
                    <Col lg={5} md={6} sm={12} xs={12}>
                        <div className="logo">
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/logo.png" alt="ChatGPT Training" className='img-fluid' />
                                <div className="logo-content">
                                    <div className="title">
                                        AI-In-Business
                                    </div>
                                    <div className="sub-title">
                                        Teaching the power of AI
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="description">
                            <p className="mb-0">
                                {t('content.why_choose_paragraph_3')}
                            </p>
                        </div>
                    </Col>
                    <Col lg={7} md={6} sm={12} xs={12}>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col xs={12}>
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <small>&copy; {t('footer.footer_title')}</small>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <small><span className="email_to_href">{htmlParser(t("footer.footer_contact"))}&nbsp;|&nbsp;<a href="/tos" target="_blank">{t('footer.footer_tos')}</a></span></small>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
