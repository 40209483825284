import React, { useState, useEffect, useRef } from "react";
import "../App.scss";
import { BsWhatsapp, BsFillXCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const WhatsappChatBot = () => {
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const chatbotRef = useRef(null);

    // Open the chatbot automatically after 10 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 10000); // 10000 ms = 10 s

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    // Add an event listener to the document to close the chatbot when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        // Cleanup function to remove the event listener when the component unmounts
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const sendMessage = () => {
        const encodedMessage = encodeURIComponent(t("whatsapp_chatbot.conversation_start") + message);
        const phoneNumber = '+41765035994';
        const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(url, '_blank');
        setMessage("");
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setMessage("");
        }
    };

    return (
        <div className="whatsapp-chatbot" ref={chatbotRef}>
            {isOpen && (
                <div className={`chat-bot ${isOpen ? "open" : ""}`}>
                    <div className="chat-header">
                        <h5><small>{t('whatsapp_chatbot.hello_message')}</small></h5>
                    </div>
                    <textarea
                        placeholder={t('whatsapp_chatbot.placeholder')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button className="send-button" onClick={sendMessage}>{t('whatsapp_chatbot.send')}</button>
                </div>
            )}
            <button className="chat-bot-toggle" onClick={handleToggle}>
                {isOpen ? <BsFillXCircleFill /> : <BsWhatsapp />}
            </button>
        </div>
    );
};

export default WhatsappChatBot;
